<template>
    <div>
        <div class="card mb-5 mb-xl-10 p-2">
            <div class="card-header border-1 cursor-pointer">
                <div class="card-title m-0">
                    <h3 class="fw-bolder m-0">{{ $t("Company Details") }}</h3>
                </div>
            </div>
            <BankCompanyDetailsForm
                :bankData="bankDetails"
            />
        </div>

        <!--    <DefineBankSettings />-->
    </div>
</template>
<script>
import SignInMethod from "@/buying-teams/shared-components/settings/SignInMethod";
import store from "@/store";
import BankCompanyDetailsForm from "@/buying-teams/pages/bank/settings/bank/BankCompanyDetailsForm";
import { BankSettingsContainer } from "@/store/models/bank/BankSettingsContainer";
import { BankUserPermissionTypeEnum } from "@/store/enums/BankUserPermissionTypeEnum";
// import DefineBankSettings from "@/buying-teams/shared-components/settings/DefineBankSettings";

export default {
    name: "Settings",

    components: {
        // DefineBankSettings,
        BankCompanyDetailsForm,
        SignInMethod
    },

    props: {
        bankDetails: BankSettingsContainer
    },

    data() {
        return {
            isActiveSecureTab: true
        };
    },

    computed: {
        currentUser() {
            return store.getters.currentUser
        },
        bankData() {
            return store.getters.bank;
        }
    },

    mounted() {
        if (this.currentUser && !this.currentUser.can(BankUserPermissionTypeEnum.EDIT_BANK_SETTINGS)) {
            this.$router.push('/bank/settings/bank-settings/overview');
        }
    }

};
</script>
<style lang="scss" scoped>

</style>
