<template>
    <div id="kt_account_profile_details" class="collapse show companyDetailsForm">
        <el-form
            class="w-xl-75"
            v-if="bankData"
            ref="bankFormRef"
            :model="bankForm"
            :rules="bankFormRules"
            label-width="300px"
        >
            <el-form-item class="fullName" :label="$t('Logo')">
                <FileUploadWithCropper
                    :imageUrl="bankForm.icon_path === Constants.BANK_TMP_LOGO ? null : bankForm.icon_path"
                    :loading="fileUploadLoading"
                    :allowedFileTypes="['png', 'jpg', 'jpeg', 'JPG']"
                    :acceptedSize="9216"
                    :blank-image-type="BlankImageTypesEnum.BANK"
                    :popup-title="$t('Crop Image')"
                    :popup-description="$t('Confirm your image upload by properly adjusting your Logo')"
                    @getImages="handleUpload"
                    @getErrors="errors => {fileUploadErrors = errors}"
                />
                <div class="el-form-item__error">{{ fileUploadErrors.join(", ") }}</div>
            </el-form-item>

            <el-form-item prop="name" :label="$t('Bank Name')">
                <el-input
                    v-model="bankForm.name"
                    :placeholder="$t('Input your Company Name')" />
            </el-form-item>

        </el-form>
        <hr />
        <div class="d-flex mt-10 justify-content-end align-items-center">
            <el-button @click="resetForm" v-if="isFormChanged">{{ $t("Discard") }}</el-button>

            <button
                :data-kt-indicator="buttonIndicator"
                :disabled="buttonIndicator === 'on' || fileUploadLoading || !isFormChanged"
                class="main-btn btn"
                @click="onSubmit()">
                <span class="indicator-label"> {{ $t("Save Changes") }}</span>

                <span class="indicator-progress">
                    {{ $t("pleaseWait") }}
                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
            </button>
        </div>
    </div>
</template>
<script>

import store from "../../../../../store";
import { Constants } from "@/core/config/constants";
import { BankSettingsContainer } from "@/store/models/bank/BankSettingsContainer";
import { DataService } from "@/core/services/DataService";
import NotificationService from "@/buying-teams/services/NotificationService";
import FileUploadWithCropper from "@/buying-teams/shared-components/utils/FileUploadWithCropper";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { BlankImageTypesEnum } from "@/store/enums/BlankImageTypesEnum";
import { BankUserPermissionTypeEnum } from "@/store/enums/BankUserPermissionTypeEnum";

export default {
    name: "BankCompanyDetailsForm",

    props: {
        bankData: BankSettingsContainer
    },

    components: {
        FileUploadWithCropper
    },

    data() {
        return {
            BlankImageTypesEnum,
            Constants,
            buttonIndicator: "off",
            fileUploadErrors: [],
            fileUploadLoading: false,
            bankForm: {
                name: "",
                icon_path: ""
            },
            bankFormInitState: "",
            bankFormRules: {
                name: [
                    { required: true, message: this.$t("Please input Group Company Name"), trigger: "change" },
                    { min: 1, max: 60, message: this.$t("Length should be 1 to 60"), trigger: "change" }
                ]
            }
        };
    },

    computed: {
        currentUser() {
            return store.getters.currentUser
        },
        isFormChanged() {
            return JSON.stringify(this.bankForm) !== this.bankFormInitState;
        }
    },

    async mounted() {
        if (this.bankData) {
            this.fillForm({ ...this.bankData.bankInfo });
            this.bankFormInitState = JSON.stringify(this.bankForm);
        }
    },

    methods: {
        async handleUpload(files) {
            if (!files.length) {
                this.bankForm.icon_path = null;
                return;
            }

            this.fileUploadLoading = true;
            store.dispatch("imageUpload", files).then(responses => {
                this.bankForm.icon_path = responses.key;
                this.fileUploadLoading = false;
            }).catch(() => {
                this.fileUploadLoading = false;
            });
        },

        async onSubmit() {
            this.buttonIndicator = "on";
            await this.$refs.bankFormRef.validate(async valid => {
                if (valid) {
                    store.dispatch("updateBankDetails", this.bankForm)
                        .then(() => {
                            NotificationService.swalCustom({
                                title: "Success",
                                icon: "success"
                            });
                            this.bankData.bankInfo.updateProfileData(
                                this.bankForm.name,
                                this.bankForm.icon_path
                            );
                            DataService.storeBankData(this.bankData.bankInfo);
                            setCurrentPageBreadcrumbs({
                                title: this.bankData.bankInfo.name,
                                logo: this.bankData.bankInfo.icon_path
                            });
                            this.bankFormInitState = JSON.stringify(this.bankForm);
                        })
                        .finally(() => {
                            this.buttonIndicator = "off";
                        });
                }
            });
        },
        fillForm(bankData) {
            this.bankForm = {
                icon_path: bankData.icon_path,
                name: bankData.name
            };
        },
        resetForm() {
            this.fillForm({ ...this.bankData.bankInfo });
        }
    },

    created() {
        if (this.currentUser && !this.currentUser.can(BankUserPermissionTypeEnum.EDIT_BANK_SETTINGS)) {
            this.$router.push('/bank/settings/bank-settings/overview');
        }
    }
};
</script>
